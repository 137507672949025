import "@properate/ui/global.css";

import { OAuthProvider, signInWithCredential } from "firebase/auth";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "@sentry/react";
import { auth } from "@properate/firebase";
import * as Sentry from "@sentry/react";
import { getCurrentUser } from "@/services/lib/getCurrentUser";
import keycloak from "@/keycloak";
import * as serviceWorker from "./serviceWorker";
import { BrowserDeprecationBar } from "./components/BrowserDeprecationBar";

Sentry.init({
  release: `properate@${import.meta.env.REACT_APP_VERSION}`,
  dsn: "https://b182c84ecd27634cd8be2183b0b18bcb@o4507770334871552.ingest.de.sentry.io/4507770345619536",
  enabled: import.meta.env.REACT_APP_SENTRY === "enabled",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay./
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. When we have more users this should be reduced to a lower value.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
});

window.global ||= window;

const root = createRoot(document.getElementById("root")!);

const render = () => {
  import("./app/App")
    .then((module) => module.default)
    .then((App) =>
      root.render(
        <ErrorBoundary showDialog>
          <BrowserDeprecationBar />
          <App />
        </ErrorBoundary>,
      ),
    );
};

keycloak.onAuthSuccess = () => {
  const provider = new OAuthProvider("oidc.properate");
  const rawNonce = sessionStorage.getItem("raw-nonce");

  if (!rawNonce) {
    throw new Error("No nonce found in session storage");
  }

  sessionStorage.removeItem("raw-nonce");

  const credential = provider.credential({
    idToken: keycloak.idToken,
    rawNonce,
  });
  localStorage.setItem("user", keycloak.idTokenParsed!.email);
  if (import.meta.env.REACT_APP_SENTRY === "enabled") {
    Sentry.setUser({ email: keycloak.idTokenParsed!.email });
  }
  signInWithCredential(auth, credential)
    // make sure we are logged into firebase
    .then(() => getCurrentUser())
    .then(render)
    .catch((error) => console.error("firebase auth failed", error));
};

if (import.meta.hot) {
  import.meta.hot.accept("./app/App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
