import Keycloak from "keycloak-js";
import { auth } from "@properate/firebase";
import sha256 from "./utils/sha256";

const keycloak = new Keycloak({
  url: "https://auth.properate.com",
  realm: import.meta.env.REACT_APP_KEYCLOAK_REALM!,
  clientId: import.meta.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
});

export const keycloakInit = keycloak.init({
  onLoad: "login-required",
  pkceMethod: "S256",
  checkLoginIframe: false,
  adapter: {
    // We need to override the default adapter to hook into the nonce generation
    // to match the expected behaviour of the Firebase Auth
    //
    // @ts-expect-error - It's fine to return a promise here, Keycloak expects it's internal promise, which is not exported
    async login(options) {
      const user = localStorage.getItem("user");
      const loginUrl = new URL(
        keycloak.createLoginUrl({ ...options, loginHint: user || "" }),
      );

      const state = loginUrl.searchParams.get("state");
      const nonce = loginUrl.searchParams.get("nonce");
      const lsKey = `kc-callback-${state}`;

      const callbackStateStr = localStorage.getItem(lsKey);
      const callbackState = JSON.parse(callbackStateStr!);

      if (!callbackState) {
        throw new Error("No callback state found");
      }

      // We need to hash the nonce before sending it to the server, because we
      // want Keycloak to retrieve an id_token with a hashed nonce to match the
      // specifications of Firebase Auth.
      const hashedNonce = await sha256(nonce!);
      callbackState.nonce = hashedNonce;
      loginUrl.searchParams.set("nonce", hashedNonce);
      localStorage.setItem(lsKey, JSON.stringify(callbackState));

      // We need to store the unhashed nonce in the session storage to be used
      // by the Firebase Auth adapter to verify the id_token.
      sessionStorage.setItem("raw-nonce", nonce!);

      window.location.href = loginUrl.toString();
    },

    logout(options): any {
      window.location.replace(keycloak.createLogoutUrl(options));
    },

    register(options): any {
      window.location.replace(keycloak.createRegisterUrl(options));
    },

    accountManagement(): any {
      window.location.replace(keycloak.createAccountUrl());
    },

    redirectUri() {
      return window.location.href;
    },
  },
});

async function updateKeycloakToken(): Promise<any> {
  return keycloak
    .updateToken(60)
    .then(function (refreshed) {
      if (refreshed) {
        console.debug("Token was successfully refreshed at:", new Date());
        console.debug("Refreshing firebase token");
        try {
          return auth.authStateReady().then(() => {
            return auth.currentUser?.getIdToken(true);
          });
        } catch (error) {
          console.error("Failed to refresh firebase token", error);
          return Promise.resolve("");
        }
      } else {
        console.debug("Token is still valid");
      }
    })
    .catch((error) => {
      console.error(
        "Failed to refresh the token, or the session has expired",
        error,
      );

      keycloak.logout();
    });
}

keycloak.onTokenExpired = async () => {
  console.debug("token expired");

  await updateKeycloakToken();
};

export const isAdmin = () =>
  keycloak.tokenParsed?.eep
    ? keycloak.tokenParsed.eep.includes("admin") ||
      keycloak.tokenParsed.eep.includes("super")
    : false;

export const isViewer = () =>
  keycloak.tokenParsed?.eep
    ? keycloak.tokenParsed.eep.includes("viewer")
    : false;

export default keycloak;
