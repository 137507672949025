import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  arrayRemove,
  arrayUnion,
  initializeFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getEnv } from "@properate/env";

export { signInAnonymously, signInWithCustomToken } from "firebase/auth";

export const app = initializeApp({
  apiKey:
    getEnv("REACT_APP_FIREBASE_API_KEY") ??
    getEnv("NEXT_PUBLIC_FIREBASE_API_KEY") ??
    "",
  authDomain:
    getEnv("REACT_APP_FIREBASE_AUTH_DOMAIN") ??
    getEnv("NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN") ??
    "",
  databaseURL:
    getEnv("REACT_APP_FIREBASE_DATABASE_URL") ??
    getEnv("NEXT_PUBLIC_FIREBASE_DATABASE_URL") ??
    "",
  projectId:
    getEnv("REACT_APP_FIREBASE_PROJECT_ID") ??
    getEnv("NEXT_PUBLIC_FIREBASE_PROJECT_ID") ??
    "",
  storageBucket:
    getEnv("REACT_APP_FIREBASE_STORAGE_BUCKET") ??
    getEnv("NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET") ??
    "",
  messagingSenderId:
    getEnv("REACT_APP_FIREBASE_MESSAGING_SENDER_ID") ??
    getEnv("NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID") ??
    "",
  appId:
    getEnv("REACT_APP_FIREBASE_APP_ID") ??
    getEnv("NEXT_PUBLIC_FIREBASE_APP_ID") ??
    "",
  measurementId:
    getEnv("REACT_APP_FIREBASE_MEASUREMENT_ID") ??
    getEnv("NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID") ??
    "",
});

export const browserFirestore = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  experimentalAutoDetectLongPolling: true,
});

export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "europe-west1");

if (
  getEnv("REACT_APP_USE_FUNCTIONS_EMULATOR") ||
  getEnv("NEXT_PUBLIC_USE_FUNCTIONS_EMULATOR")
) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5002);
}

if (
  getEnv("REACT_APP_USE_STORAGE_EMULATOR") ||
  getEnv("NEXT_PUBLIC_USE_STORAGE_EMULATOR")
) {
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}

if (
  getEnv("REACT_APP_USE_AUTH_EMULATOR") ||
  getEnv("NEXT_PUBLIC_USE_AUTH_EMULATOR")
) {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

if (
  getEnv("REACT_APP_USE_FIRESTORE_EMULATOR") ||
  getEnv("NEXT_PUBLIC_USE_FIRESTORE_EMULATOR")
) {
  connectFirestoreEmulator(browserFirestore, "127.0.0.1", 8080);
}

type User = {
  uid: string;
  email: string;
};

export function getCurrentUser(): Promise<User> {
  const user = getAuth(app).currentUser as User | null;
  if (user) {
    return Promise.resolve(user);
  }
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user as User);
    }, reject);
  });
}

export function getPossibilyCurrentUser() {
  return getAuth(app).currentUser as User | null;
}

export function getArrayDiffOp<T>(patch: T[], current: T[]) {
  if (current.length > patch.length) {
    return arrayRemove(...current.filter((item) => !patch.includes(item)));
  }

  return arrayUnion(...patch.filter((item) => !current.includes(item)));
}
