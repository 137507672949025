// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export function getEnv(key: string, defaultValue?: string): string | undefined {
  if (typeof import.meta.env !== "undefined") {
    return import.meta.env[key] ?? defaultValue;
  }

  if (typeof process !== "undefined") {
    return process.env[key] ?? defaultValue;
  }

  throw new Error("The environment variables are not available");
}
